<template>
    <el-scrollbar>
        <el-menu class="sidebar" background-color="#2f3447" text-color="#ffffff" active-text-color="#ffffff"
            :default-active="$route.path" router>
            <el-image class="logo" :src="require('@/assets/img/logo-white.png')" fit="contain" />
            <el-menu-item index="/index">
                <i class="el-icon-s-home" />
                <span slot="title">首页</span>
            </el-menu-item>
            <el-menu-item index="/customer">
                <i class="el-icon-s-custom" />
                <span slot="title">客户管理</span>
            </el-menu-item>

            <el-submenu index="/terminal">
                <template slot="title">
                    <i class="el-icon-s-opportunity" />
                    <span slot="title">智能终端</span>
                </template>
                <el-menu-item index="/terminal/type">
                    <i class="el-icon-" />
                    <span slot="title">产品系列</span>
                </el-menu-item>
                <el-menu-item index="/terminal/model">
                    <i class="el-icon-" />
                    <span slot="title">产品型号</span>
                </el-menu-item>
                <el-menu-item index="/terminal/device">
                    <i class="el-icon-" />
                    <span slot="title">终端管理</span>
                </el-menu-item>
                <el-menu-item index="/terminal/firmware">
                    <i class="el-icon-" />
                    <span slot="title">终端升级</span>
                </el-menu-item>
            </el-submenu>
            <el-submenu index="/iot">
                <template slot="title">
                    <i class="el-icon-s-opportunity" />
                    <span slot="title">物联平台</span>
                </template>
                <el-menu-item index="/product">
                    <i class="el-icon-" />
                    <span slot="title">产品项目</span>
                </el-menu-item>
                <el-menu-item index="/device">
                    <i class="el-icon-" />
                    <span slot="title">设备管理</span>
                </el-menu-item>
                <el-submenu index="/asset">
                    <template slot="title">
                        <i class="el-icon-s" />
                        <span slot="title">耗材服务</span>
                    </template>
                    <el-menu-item index="/asset/model">
                        <i class="el-icon-" />
                        <span slot="title">耗材型号</span>
                    </el-menu-item>
                    <el-menu-item index="/asset/asset">
                        <i class="el-icon-" />
                        <span slot="title">耗材管理</span>
                    </el-menu-item>
                </el-submenu>
            </el-submenu>

            <el-menu-item index="/application/list">
                <i class="el-icon-" />
                <span slot="title">物联应用</span>
            </el-menu-item>
            <el-submenu index="/system">
                <template slot="title">
                    <i class="el-icon-s-tools" />
                    <span slot="title">系统管理</span>
                </template>
                <el-menu-item index="/system/staff">
                    <i class="el-icon-" />
                    <span slot="title">员工管理</span>
                </el-menu-item>
                <el-menu-item index="/system/file">
                    <i class="el-icon-" />
                    <span slot="title">文件管理</span>
                </el-menu-item>
            </el-submenu>
            <div class="edition">2024.1.6 V1</div>
            <div style="height: 32px" />
        </el-menu>
    </el-scrollbar>
</template>

<script>
export default {
    data() {
        return {};
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style scoped>
.el-scrollbar {
    height: 100vh;
}

.el-scrollbar>>>.el-scrollbar__wrap {
    overflow-x: hidden;
}

.sidebar {
    min-height: 100vh;

    border-right: none;
}

.logo {
    height: 30px;

    margin: 25px auto;
}

li {
    text-align: left;
}

.el-menu-item:hover,
.el-submenu>>>.el-submenu__title:hover,
.el-submenu>>>.el-menu-item:hover {
    background: #262a39 !important;
}

.el-menu-item.is-active,
.el-submenu>>>.el-menu-item.is-active {
    background: #4165d7 !important;
}

.el-submenu svg {
    margin: 0 8px 0 3px;
}

.edition {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    color: #fff;
    line-height: 2em;
}
</style>
