<template>
  <div class="component">
    <div class="button" @click="scroll(true)">
      <i class="el-icon-arrow-left" />
    </div>
    <div class="scroller" ref="scroller">
      <div
        v-for="(item, index) in tabList"
        :key="index"
        class="button"
        :ref="`item-${index}`"
        :class="$route.path === item.route ? 'active' : ''"
        @click="navigateTo(index)"
      >
        <span>{{ item.name }}</span>
        <i class="el-icon-close" @click.stop="close(index)" />
      </div>
    </div>
    <div class="button" @click="scroll(false)">
      <i class="el-icon-arrow-right" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabList: [{ name: "首页", route: "/index" }],
    };
  },
  created() {
    this.$bus.$on("switchTabs", (tab) => {
      let index = this.tabList.findIndex((item) => tab.route === item.route);
      if (index === -1) {
        this.tabList.push(tab);
        index = this.tabList.length - 1;
      }
      this.$nextTick(() => {
        this.addScroll(index);
      });
    });
  },
  mounted() {},
  destroyed() {
    this.tabList.forEach((item) => {
      this.$bus.$emit("closePage", item.route);
    });
  },
  methods: {
    scrollTo(left) {
      this.$refs["scroller"].scrollTo({
        left,
        behavior: "smooth",
      });
    },
    addScroll(index) {
      if (!this.$refs[`item-${index}`]) {
        return;
      }
      const el = this.$refs[`item-${index}`][0];

      if (el) {
        this.scrollTo(
          el.offsetLeft + el.clientWidth - this.$refs["scroller"].clientWidth
        );
      }
    },
    scroll(isLeft) {
      this.scrollTo(this.$refs["scroller"].scrollLeft + (isLeft ? -100 : 100));
    },
    navigateTo(index) {
      this.addScroll(index);
      this.$router.push(this.tabList[index].route);
    },
    close(index) {
      if (this.$route.path === this.tabList[index].route) {
        if (index !== this.tabList.length - 1) {
          this.navigateTo(this.tabList.length - 1);
        } else {
          this.navigateTo(this.tabList.length - 2);
        }
      }
      this.$bus.$emit("closePage", this.tabList[index].route);
      this.tabList.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.component {
  display: flex;
  align-items: center;

  height: 50px;

  padding: 10px;

  background: #f7f7f7;
}

.button {
  display: flex;
  align-items: center;

  height: 30px;

  padding: 0 2px;

  line-height: 30px;
  background-color: #fff;
  border-radius: 3px;
  cursor: pointer;
}
.component > .button:hover {
  background-color: #eee;
}

.scroller > .button {
  margin-right: 10px;
  padding: 0 10px;

  font-size: 12px;
  color: #909399;
}
.scroller > .button:last-child {
  margin-right: 0;
}

.scroller > .button.active > span {
  color: #4165d7;
}

.scroller > .button > i {
  width: 0;

  margin-left: 5px;

  font-size: 14px;
  overflow: hidden;
  transition: all 0.3s;
}
.scroller > .button.active > i,
.scroller > .button:hover > i {
  width: 14px;

  margin-left: 5px;
}
.scroller > .button > i:hover {
  color: #fff;
  background-color: #4165d7;
}
.scroller > .button:first-child > i {
  display: none;
}

.scroller {
  flex: 1;

  display: flex;
  align-items: center;

  width: 100%;

  margin: 0 10px;

  overflow: hidden;
  white-space: nowrap;
}
</style>
