import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        redirect: '/index',
    },
    {
        path: '/login',
        component: () => import('../views/user/Login.vue'),
    },
    {
        path: '/index',
        component: () => import('../views/index/home/Home.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/myProfile',
        component: () => import('../views/user/info/Info.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/editPW',
        component: () => import('../views/user/editPW/EditPW.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/system/staff',
        component: () => import('../views/main/staff/home/Home.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/system/file',
        component: () => import('../views/main/file/home/Home.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/customer',
        component: () => import('../views/main/customer/home/Home.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/customer/:id',
        component: () => import('../views/main/customer/one/One.vue'),
        meta: {
            showLayout: true,
        }
    },
    {
        path: '/product',
        component: () => import('../views/main/product/home/Home.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/product/:id',
        component: () => import('../views/main/product/one/One.vue'),
        meta: {
            showLayout: true,
        }
    },
    {
        path: '/device',
        component: () => import('../views/main/device/home/Home.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/device/:id',
        component: () => import('../views/main/device/one/One.vue'),
        meta: {
            showLayout: true,
        }
    },
    {
        path: '/terminal/type',
        component: () => import('../views/main/terminal/type/home/Home.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/terminal/model',
        component: () => import('../views/main/terminal/model/home/Home.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/terminal/model/:id',
        component: () => import('../views/main/terminal/model/one/One.vue'),
        meta: {
            showLayout: true,
        }
    },
    {
        path: '/terminal/device',
        component: () => import('../views/main/terminal/device/home/Home.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/terminal/device/:id',
        component: () => import('../views/main/terminal/device/one/One.vue'),
        meta: {
            showLayout: true,
        }
    },
    {
        path: '/terminal/firmware',
        component: () => import('../views/main/terminal/firmware/home/Home.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/terminal/firmware/:id',
        component: () => import('../views/main/terminal/firmware/one/One.vue'),
        meta: {
            showLayout: true,
        }
    },
    {
        path: '/asset/model',
        component: () => import('../views/main/asset/model/home/Home.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/asset/model/:id',
        component: () => import('../views/main/asset/model/one/One.vue'),
        meta: {
            showLayout: true,
        }
    },
    {
        path: '/asset/asset',
        component: () => import('../views/main/asset/asset/home/Home.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/asset/asset/:id',
        component: () => import('../views/main/asset/asset/one/One.vue'),
        meta: {
            showLayout: true,
        }
    },
    {
        path: '/application/type',
        component: () => import('../views/main/application/type/home/Home.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/application/version',
        component: () => import('../views/main/application/version/home/Home.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/application/list',
        component: () => import('../views/main/application/application/home/Home.vue'),
        meta: {
            showLayout: true,
            keepAlive: true
        }
    },
    {
        path: '/application/list/:id',
        component: () => import('../views/main/application/application/one/One.vue'),
        meta: {
            showLayout: true,
        }
    },
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router